import About from "./partComponents/About";
import Catalog from "./partComponents/Сatalog";

export default function Main() {
  return (
    <>
      <About />
      <Catalog />
    </>
  );
}
